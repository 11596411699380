/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'ngx-toastr/toastr'; 


// @font-face {
//     font-family: 'century_gothicregular';
//     src: url('./assets/fonts/centurygothic-webfont.woff2') format('woff2'),
//          url('./assets/fonts/centurygothic-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'century_gothicbold';
//     src: url('./assets/fonts/centurygothic_bold-webfont.woff2') format('woff2'),
//          url('./assets/fonts/centurygothic_bold-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 27, 2024 */



@font-face {
    font-family: 'avenirheavy';
    src: url('./assets/fonts/avenir_heavy-webfont.woff2') format('woff2'),
         url('./assets/fonts/avenir_heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenirlight';
    src: url('./assets/fonts/avenir_light-webfont.woff2') format('woff2'),
         url('./assets/fonts/avenir_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenir_regularregular';
    src: url('./assets/fonts/avenir_regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/avenir_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

body {
    background-color: #F7F8FA !important;
    // font-family: 'century_gothicregular';
    font-family: 'avenir_regularregular';
}

.form-control {
    &::placeholder {
        font-size: 14px;
        color: #6B7280;
    }
}
.navbar {
    background-color: #F7F8FA !important;
    width: 94%;
    margin: 0 auto;
   .navbar-brand {
    img {
        width: 138px;
        height: 46px;
        object-fit: contain;
    }
   }
    .header-search-field {
        .form-control {
            background-color: #fff;
            padding: 7px 10px 7px 20px;
            width: 350px;
            box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
        }
        .fa-search {
            position: absolute;
            top: 10px;
            right: 20px;
            opacity: .3;
        }
    }
    .navbar-nav {
        li {
            padding: 0;
            margin-left: 30px;
            a {
                text-decoration: none;
                text-align: center;
                color: #9B9CA1;
                i {
                    font-size: 20px;
                }
                &:hover {
                    color: #FF1C24;
                i{color: #1E74FD;}
                }
            }
        }
    }
    .userblock-nav {
        li {
            margin: 0;
            a {
                background-color: transparent;
                color:#1E74FD ;
                &:hover {
                    background-color: transparent;
                    i{color: #adb5bd;}
                    }
            }
        }
    }
}

.leftBar-block {
    margin-left: 20px;
    margin-right: 20px;
}
.card {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border: 0;
    margin-bottom: 15px;
}

.main-panel {
    position: relative;
    // z-index: 1;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}



.btn-close-modal {
    background-color: #414141;
    border-radius: 50px;
    height: 25px;
    width: 25px;
    line-height: 18px;
    text-align: center;
    padding: 0;
    margin-right: -20px;
    margin-top: -50px;
    border: 0;
}


/*All Round Classes and BG CLasses*/
.bg-greylight {
    background-color: #f5f5f5;
}
.bg-lightblue {
    background-color: #e8f1fa;
}
.bg-light {
    background-color: #F6F6F6 !important;
}
.btn-round-xss { width: 8px; height: 8px; border-radius: 8px; display: inline-block;line-height: 25px !important; text-align: center; i {line-height: 25px !important; }}
.btn-round-xs { width: 25px; height: 25px; border-radius: 25px; display: inline-block;line-height: 25px !important; text-align: center; i {line-height: 25px !important; }}
.btn-round-sm { width: 35px; height: 35px; border-radius: 35px; display: inline-block;line-height: 35px !important; text-align: center; i {line-height: 35px !important; }}
.btn-round-md { width: 40px; height: 40px; border-radius: 40px; display: inline-block;line-height: 40px !important; text-align: center; i {line-height: 40px; }}
.btn-round-lg { width: 50px; height: 50px; border-radius: 50px; display: inline-block;line-height: 50px; text-align: center; i {line-height: 50px; }}
.btn-round-xl { width: 60px; height: 60px; border-radius: 60px; display: inline-block;line-height: 60px; text-align: center; i {line-height: 60px; } img {line-height: 60px; }}
.btn-round-xxl { width: 70px; height: 70px; border-radius: 70px; display: inline-block;line-height: 70px; text-align: center; i {line-height: 70px; }}
.btn-round-xxxl { width: 80px; height: 80px; border-radius: 80px; display: inline-block;line-height: 80px; text-align: center; i {line-height: 80px; }}
.btn-round-xxxxl { width: 110px; height: 110px; border-radius: 110px; display: inline-block;line-height: 110px; text-align: center; i {line-height: 110px; }}

.bg-current {background-color: var(--theme-color) !important;}
.bg-current-shade {background-color: var(--theme-color-shade) !important;}
.bg-primary-gradiant {background: linear-gradient(to right, #0575e6, #021b79) !important;}
.bg-gold-gradiant {background: linear-gradient(to right, #f2994a, #f2c94c)!important;}
.bg-red-gradiant {background:  linear-gradient(to right, #e44d26, #f16529)!important;}
.bg-mini-gradiant {background:linear-gradient(to right, #ee0979, #ff6a00) !important;}
.bg-blue-gradiant {background: linear-gradient(135deg, #05f, #09f);}
.bg-lightblue2 {
    background-color: #f7f9fd !important;
}
.bg-twiiter {
    background-color: #3b5999 !important;
}


.cursor-pointer {
    cursor: pointer;
}


.w35 {
    width: 35px !important;
}
.w45 {
    width: 45px !important;
}
.fs-10 {
    font-size: 10px
}
.fs-12 {
    font-size: 12px
}
.fs-14 {
    font-size: 14px
}
.fs-18 {
    font-size: 18px
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}

.border-dotted {
    border: dotted 2px #ccc;
}

textarea.form-control {
    padding-left: 50px;
}

.comment-block {
    position: relative;
    img {
        position: absolute;
        top: 8px;
        left: 10px;
        width: 28px;
        height: 28px;
        
    }  
}

.filter-search-block {
    .form-control {
        height: 45px;
        line-height: 45px;
        padding-left: 20px;
        font-weight: 500;
      
    }
    .header-search-field {
        i {
            position: absolute;
            right: 15px;
            top: 14px;
            opacity: 0.3;
        }
    }
    .btn-square {
        line-height: 40px;
        padding: 0 16px;
        border-radius: 6px;
        margin-left: 10px;
        color: #ccc;
    }
}


.account-detail-form {
    .form-control, .form-select {
        line-height: 44px;
        height: 44px;
        font-size: 14px;
        display: inline-block;
        border-radius: 30px;
        border: 1px solid #E3E3E3;
        padding-top: 0;
        padding-bottom: 0;
    }
    .form-select {
        padding-top:0 ;
        padding-bottom:0 ;
    }
    form {
        border: 1px solid #DFDFDF;
    }
}


.heading-title {
    display: flex;
    justify-content: space-between;
    h2 {
        font-size: 18px;
        font-weight: 700;
    }
    button {
        font-size: 13px;
        display: flex;
    align-items: center;
    gap: 20px;
    }
}


// @media only screen and (max-width: 600px) {
//     .main-content-block {
//         width: 100%;
//         margin: 0;
//     }  
// }

.inner-main-wrapper-section {
    padding: 20px;
    // margin-top: 105px;
    border-radius: 10px;
    background: var(--White-White, #FFF);
    box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
    position: relative;
}

.main-content-block {
    width: 94%;
    margin: 0 auto;
    padding-top: 110px;
}
.inner-wrapper {
    width: 1020px;
    margin: 0 auto;
}
.inner-wrapperlogin {
    width: 100%;
    margin: 0 auto;
}


/*Commun Css Styling Add*/
.loging-header {
    position: fixed;
    top: 0;
    left: unset;
    right: 0;
    width: fit-content;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 96px;
    padding: 0 15px 0 0;
    -webkit-transition: left 0.25s;
    -o-transition: left 0.25s;
    transition: left 0.25s;
}

.login-card {
    min-width: 500px;
    max-width: 500px;
    margin: 0 auto;
    a {
        color: #FA454C;
    }
    .logo-block {
        text-align: center;
        margin-bottom: 20px;
        a.navbar-brand {
            margin-bottom: 10px;
            display: block;
            img {
                height: 30px;
            }
        }
        
        h2 {
            margin-bottom: 20px;
            font-size: 33px;
            // font-family: 'century_gothicregular';
            font-family: 'avenir_regularregular';
        }
        h6 {
            font-size: 16px;
            color: #8A92A6;
            font-weight: 400;
             // font-family: 'century_gothicregular';
             font-family: 'avenir_regularregular';
        }
    }
    form {
        label {
            color: #8A92A6 !important;
            margin-bottom: 8px;
             // font-family: 'century_gothicregular';
             font-family: 'avenir_regularregular';
        }
    }
    .form-control {
        height: 44px;
        line-height: 44px;
        display: block;
        border: 1px solid #E5E7EA !important;
        border-radius: 6px;
        padding-left: 15px;
        font-size: 16px;
    }
    textarea.form-control {
        height: 100px;
    }
    .form-block {
        position: relative;
        i {
            position: absolute;
            left: 15px;
            top: 14px;
        }
    }
}
.social-icon {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
    a {
        display: block;
        background-color: #333;
        border-radius: 30px;
        height: 24px;
        width: 24px;
        line-height: 24px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        &:hover {
            background-color: #777;
        }
    }
}
.btn {
    background-color: #FA454C;
    color: #fff;
    border-radius: 30px;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 13px;
    &:hover {
        background-color: #d8373c;
        color: #fff;
    }
}
.btn-dark {
    background-color: #404048;
    &:hover {
        background-color: #000;
    }
}




hr {
    border-color: #E8EAEC;
    opacity: 1;
}




.language-btn-block {
    .language-btn {
        // display: flex;
        gap: 5px;
        align-items: center;
        background-color: #fd444c;
        border: 1px solid #fd444c;
        padding: 4px 10px;
        border-radius: 30px;
        color: #fff;
        img {
            filter: brightness(2);
        }
        
        span {
            width: 30px;
            margin-left: 5px;
        }
        
    }
    
    .dropdown-menu {
        position: absolute;
        right:0 !important;
        left: auto;
    }
}


// .loging-header {
//     display: none !important;
// }



/*Footer Block Start*/
.footer-block {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      margin-top: 40px;
      li {
        list-style: none;
        a {
          text-decoration: none;
          color: #404048;
          padding: 0 25px;
          line-height: 13px;
          border-right: 1px solid #404048;
          display: inline-block;
          
        }
        &:first-of-type {
          a {
            padding-left: 0;
          }
        }
        &:last-of-type {
          a {
            border-right: 0;
            padding-right: 0;
          }
        }
      }
    }
  }

  .form-select {
    
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
background-image: url(../src/assets/arrow-icon-gray.svg);
background-repeat: no-repeat;
background-size: 12px;
  text-indent: 0.01px;
  }

  .navbar-expand-lg .navbar-collapse {
    justify-content: center;
  }

/*Header Navbar CSS*/
.page-link {
    font-size: 14px;
}
.page-link.active {
    background-color: #fd444c;
    border: 1px solid #fd444c;
    color: #fff !important;
}


.modal-dialog .modal-header {
    justify-content: space-between;
}

@media screen and (max-width:1600px){
    .main-panel {
        z-index: auto;
    }
    .rightBar-block {
        display: none;
    }
    .navbar {
        width: 100%;
    }
    .main-content-block {
        width: 100%;
        padding-top: 110px;
    }  
    .inner-main-wrapper-section {
        margin-top: 0;
    }
}

@media screen and (max-width:1600px){
    .inner-wrapper {
        width: 100%;
    }

}



@media screen and (max-width:991px){
    .main-panel {
        z-index: auto;
    }
    .main-content-block {
        width: 100%;
        margin: 0;
        padding-top: 70px;
    } 
    
}
@media screen and (max-width:767px){
    .main-panel {
        z-index: auto;
    }
    .main-content-block {
        width: 100%;
        margin: 0;
        padding-top: 70px;
    }
    .navigation {
        overflow-y: scroll;
    }
    .account-detail-form {
        .btn {
            width: 100% !important;
        }
    }
    .logo-block {
        img {
            height: 35px;
        }
    }
    .navbar-expand-lg .navbar-collapse {
        padding-bottom: 40px;
     }
    .navbar {
        .navbar-nav {
            padding: 40px 0;
            gap: 0 !important;
            li {
                margin: 12px 0;
                border-bottom: 1px solid #ddd;
                padding-bottom: 12px;
            }
        }
    }
    .language-btn-block {
        .language-btn {
            background-color: transparent;
            border: 1px solid #fd444c;
            padding: 0 10px;
            border-radius: 30px;
            color: #fff;
            height: 40px;
            display: flex;
            span {
                margin: 0;
                color: #fd444c;
                width: auto;
            }
        }
    }
    .login-card {
        min-width: 100%;
        max-width: 100%;
    }
    .heading-title {
        flex-direction: column;
    }
    .leftmenubar-block {
        margin-bottom: 20px;
    }

    .btn {
        width: 100% !important;
    }
    .login-card {
        .logo-block {
            a.navbar-brand {
                text-align: left;
                margin-bottom: 30px;
            }
        }
    }
    
}


